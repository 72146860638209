import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[(_vm.mode===1)?_c(VRow,{staticClass:"pl-4"},[(_vm.isImage)?_c(VCol,{staticClass:"shrink clickable",on:{"click":_vm.fireClickEvent}},[(_vm.isImage)?_c('ThumbnailView',{attrs:{"height":70,"width":70,"imageId":_vm.fileId}}):_vm._e()],1):_vm._e(),(!_vm.isImage)?_c(VCol,{staticClass:"shrink clickable"},[(_vm.downloadType === 's3')?_c('a',{attrs:{"href":_vm.downloadUrl,"target":"_blank","rel":"noopener"}},[(_vm.iconType === 'pdf')?_c('span',[_c('i',{staticClass:"far fa-4x fa-file-pdf"})]):_vm._e(),(_vm.iconType === 'file')?_c('span',[_c('i',{staticClass:"far fa-4x fa-file"})]):_vm._e()]):_vm._e(),(_vm.downloadType === 'api')?_c('a',{attrs:{"rel":"noopener"},on:{"click":function($event){$event.preventDefault();_vm.onDownloadFile(_vm.buildApiServerUrl(_vm.downloadUrl),_vm.fileName)}}},[(_vm.iconType === 'pdf')?_c('span',[_c('i',{staticClass:"far fa-4x fa-file-pdf"})]):_vm._e(),(_vm.iconType === 'file')?_c('span',[_c('i',{staticClass:"far fa-4x fa-file"})]):_vm._e()]):_vm._e()]):_vm._e(),_c(VCol,[_c(VContainer,[_c(VRow,[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.fileName))])]),_c(VRow,{attrs:{"justify":"end"}},[(_vm.canDelete)?_c(VBtn,{attrs:{"text":"","color":"orange"},on:{"click":_vm.fireDeleteEvent}},[_vm._v("DELETE")]):_vm._e()],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }