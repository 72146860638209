import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"timeline",attrs:{"fluid":""}},[_c(VLayout,{attrs:{"wrap":""}},[_vm._l((_vm.list),function(dateData,idx){return _c(VContainer,{key:dateData.date + '_' + dateData.new_arrivals},[_c('h3',[(dateData.new_arrivals)?_c('span',[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"red lighten-1"}},[_vm._v("fiber_new")])],1):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.formatDateToHeaderDisplay(dateData.date)))])]),_vm._l((dateData.userEntries),function(userEntry){return _c(VCard,{key:userEntry.staff_cd,staticClass:"pa-3 mt-3"},[_c(VListItem,[_c('ProfileImageView',{attrs:{"staff-cd":userEntry.staff_cd}}),_c(VListItemContent,{staticClass:"ml-3"},[_c(VListItemTitle,{staticClass:"headline"},[_vm._v(_vm._s(userEntry.user_name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(userEntry.group_name))])],1)],1),_vm._l((userEntry.entries),function(entry,idx){return [(entry.isLastEntry)?_c('IntersectionObserver',{on:{"intersect":function($event){return _vm.onLastEntryIntersected(entry)}}}):_vm._e(),(idx > 0)?_c(VDivider):_vm._e(),_c('ReportDetail',{attrs:{"report-id":entry.reportId}}),_c('ReportComments',{attrs:{"report-id":entry.reportId}}),(_vm.useAutoRead && !entry.read_event_fired)?_c('IntersectionObserver',{on:{"intersect":function($event){return _vm.onEntryIntersected(entry)}}}):_vm._e(),_c('ReportForwards',{attrs:{"report-id":entry.reportId}})]})],2)})],2)}),_c('loading-view',{attrs:{"loading":_vm.loading}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }