<template>
  <v-card>
    <v-list class="notice-list" dense>
      <v-list-item
        v-for="n in notifications"
        :key="n.noticeId"
        v-on:click.prevent="onNoticeClick(n)"
      >
        <template v-if="n.noticeType === 'emote'">
          <v-list-item-content>
            <v-list-item-title>
              <a :class="{ new: n.state2 == 0 }">
                <span>{{ n.emoteStaffName }}さんが日報に</span>
                <span>「 {{ getEmoteName(n.emoteCd) }} 」</span>
                <span>しました。</span>
              </a>
            </v-list-item-title>
            <v-list-item-subtitle
              >({{ formatNoticeDate(n.occurTime) }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>

        <template v-if="n.noticeType === 'cmt_emote'">
          <v-list-item-content>
            <v-list-item-title>
              <a :class="{ new: n.state2 == 0 }">
                <span>{{ n.emoteStaffName }}さんがコメントに</span>
                <span>「 {{ getEmoteName(n.emoteCd) }} 」</span>
                <span>しました。</span>
              </a>
            </v-list-item-title>
            <v-list-item-subtitle
              >({{ formatNoticeDate(n.occurTime) }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>

        <template v-if="n.noticeType === 'rep_emote'">
          <v-list-item-content>
            <v-list-item-title>
              <a :class="{ new: n.state2 == 0 }">
                <span>{{ n.emoteStaffName }}さんが返信に</span>
                <span>「 {{ getEmoteName(n.emoteCd) }} 」</span>
                <span>しました。</span>
              </a>
            </v-list-item-title>
            <v-list-item-subtitle
              >({{ formatNoticeDate(n.occurTime) }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>

        <template v-if="n.noticeType === 'comment'">
          <v-list-item-content>
            <v-list-item-title>
              <a :class="{ new: n.state2 == 0 }">
                <span
                  >{{ n.commentStaffName }}さんが日報にコメントしました。</span
                >
              </a>
            </v-list-item-title>
            <v-list-item-subtitle
              >({{ formatNoticeDate(n.occurTime) }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>

        <template v-if="n.noticeType === 'reply'">
          <v-list-item-content>
            <v-list-item-title>
              <a :class="{ new: n.state2 == 0 }">
                <span
                  >{{ n.replyStaffName }}さんがコメントに返信しました。</span
                >
              </a>
            </v-list-item-title>
            <v-list-item-subtitle
              >({{ formatNoticeDate(n.occurTime) }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>

        <template v-if="n.noticeType === 'forward'">
          <v-list-item-content>
            <v-list-item-title>
              <a :class="{ new: n.state2 == 0 }">
                <span
                  >{{
                    n.forwardFromStaffName
                  }}さんから日報が転送されました。</span
                >
              </a>
            </v-list-item-title>
            <v-list-item-subtitle
              >{{ n.forwardComment }} <br />({{
                formatNoticeDate(n.occurTime)
              }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>

        <template v-if="n.noticeType === 'forwarded_to'">
          <v-list-item-content>
            <v-list-item-title>
              <a :class="{ new: n.state2 == 0 }">
                <span
                  >{{ n.forwardToStaffName }}さんへ日報が転送されました。</span
                >
              </a>
            </v-list-item-title>
            <v-list-item-subtitle
              >{{ n.forwardComment }} <br />({{
                formatNoticeDate(n.occurTime)
              }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>

        <template v-if="n.noticeType === 'impr'">
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="n.wfState == 1">改善提案が起票されました。 </span>
              <span v-if="n.wfState == 3"
                >改善提案が上長により承認されました。
              </span>
              <span v-if="n.wfState == 5">改善提案が公開されました。 </span>
            </v-list-item-title>
            <v-list-item-subtitle
              >({{ formatNoticeDate(n.occurTime) }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import moment from "moment";
import Common from "@/components/Common";
import NotificationSupport from "@/components/NotificationSupport";
export default {
  mixins: [NotificationSupport, Common],
  mounted: function () {},
  destroyed() {},
  data() {
    return {
      emoteModel: false,
    };
  },
  computed: {},
  methods: {
    fetchData() {},
    onNoticeClick(notice) {
      this.$emit("noticeClick", notice);
    },
    formatNoticeDate(dt) {
      if (dt) {
        return moment(dt).format("MM月DD日 HH:mm");
      } else {
        return "";
      }
    },
  },
};
</script>
