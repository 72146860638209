import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,{staticClass:"left-drawer",attrs:{"dense":""}},[_vm._l((_vm.items),function(item){return [(item.heading)?_c(VRow,{key:item.heading,attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"6"}},[(item.heading)?_c(VSubheader,[_vm._v(" "+_vm._s(item.heading)+" ")]):_vm._e()],1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c('a',{staticClass:"body-2 black--text",attrs:{"href":"#!"}},[_vm._v("EDIT")])])],1):(item.children && _vm.checkMenuVisible(item))?_c(VListGroup,{key:item.text,staticClass:"ml-2",attrs:{"prepend-icon":item.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v(_vm._s(item.text))])]},proxy:true}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}},[_vm._l((item.children),function(child,i){return [(_vm.checkMenuVisible(child))?_c(VListItem,{class:{ active: child.id === _vm.selectedMenuId },on:{"click":function($event){return _vm.menuItemClick(child)}}},[_c(VListItemAction),_c(VListItemContent,{staticClass:"pr-5"},[_c(VListItemTitle,[_vm._v(" "+_vm._s(child.text)+" ")])],1)],1):_vm._e()]})],2):(_vm.checkMenuVisible(item))?_c(VListItem,{key:item.id,class:{ active: item.id === _vm.selectedMenuId },on:{"click":function($event){return _vm.menuItemClick(item)}}},[_c(VListItemAction,[(item.icon)?_c(VIcon,{staticClass:"ml-2"},[_vm._v(_vm._s(item.icon))]):_vm._e(),(item.iconImg)?_c('img',{attrs:{"src":'/icons/' + item.iconImg,"alt":item.iconImg,"width":"36","height":"38"}}):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.text)+" ")])],1),(item.badge)?_c(VListItemIcon,[(
            item.badge === 'unreadThanksCardCount' &&
            _vm.unreadThanksCardCount > 0
          )?_c(VChip,{staticClass:"my-2",attrs:{"x-small":"","color":"#E57373","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.unreadThanksCardCount)+" ")]):_vm._e()],1):_vm._e()],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }