import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"400","content-class":"user-select-dialog"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("ユーザ選択")])]),_c(VCardText,{staticClass:"body-1 pt-5 black--text"},[_c(VTextField,{attrs:{"label":"名前、担当者CDを入力","clearable":"","autocomplete":"off"},model:{value:(_vm.userFilter),callback:function ($$v) {_vm.userFilter=$$v},expression:"userFilter"}}),_c(VContainer,{staticStyle:{"min-height":"100px"}},[_c(VRow,{attrs:{"dense":""}},[_vm._l((_vm.userList),function(item,index){return _c(VCol,{key:item.user_id,attrs:{"cols":"4"}},[_c('span',[_c('a',{staticClass:"staff-name",on:{"click":function($event){$event.preventDefault();return _vm.selectUser(item)}}},[_vm._v(_vm._s(item.name))])])])}),(_vm.isOmitted)?_c(VCol,{attrs:{"cols":"4"}},[_vm._v("・・省略")]):_vm._e()],2)],1)],1),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_c(VBtn,{nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("キャンセル")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }