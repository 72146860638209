import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onCancelClick.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("担当者へ転送")])]),_c(VCardText,[_c(VContainer,[_c(VForm,{ref:"formForward",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,_vm._l((_vm.staff_cd_list),function(staff,index){return _c(VCol,{staticClass:"pt-0 pb-0 mt-0 mb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('UserSelectField',{attrs:{"required":index === 0,"label":"お知らせしたい人"},model:{value:(_vm.staff_cd_list[index]),callback:function ($$v) {_vm.$set(_vm.staff_cd_list, index, $$v)},expression:"staff_cd_list[index]"}})],1)}),1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"rules":_vm.forwardCommentRules,"counter":50,"label":"コメント","required":"","maxlength":"50"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1)],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.onSaveClick.apply(null, arguments)}}},[_vm._v("保存")]),_c(VBtn,{attrs:{"color":"grey lighten-4"},nativeOn:{"click":function($event){return _vm.onCancelClick.apply(null, arguments)}}},[_vm._v("キャンセル")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }