import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"login",attrs:{"fluid":"","fill-height":""}},[_c(VContainer,[_c(VLayout,{attrs:{"wrap":"","justify-center":""}},[_c(VCard,{staticClass:"elevation-1 pa-3 login-card"},[_c(VCardText,[_c(VForm,{ref:"loginForm"},[_c(VTextField,{attrs:{"append-icon":"person","name":"login","label":"ユーザID","type":"text","required":"","maxlength":"30","id":"userNameField","placeholder":" "},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}}),_c(VTextField,{attrs:{"append-icon":"lock","name":"password","label":"パスワード","type":"password","rules":_vm.passwordRules,"required":"","maxlength":"32","id":"passwordField","placeholder":" "},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})],1)],1),_c('div',{staticClass:"login-btn"},[_c(VBtn,{attrs:{"block":"","color":"primary","loading":_vm.loading,"id":'loginBtn'},on:{"click":_vm.login}},[_vm._v("ログイン")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }