import axios from 'axios';
import Util from '../Util'

class BasicInfoAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    getMinClientVersion() {
        let path = "/min_client_version";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }


    listGroup(){

        let path = "/api/groups";


        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    listUser(name){

        let path = "/api/users";


        let endpoint =  Util.combineUrl(this.apiHost,path);

        if (name){
            endpoint = Util.buildParamUrl(endpoint,{name:name});
        }

        return axios.get(endpoint).then(response =>  response.data)

    }

    listEmote(){
        let path = "/api/emotes";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }

    getMyChiefUser(){
        let path = "/api/maingroupchief";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }

}

let _api = new BasicInfoAPI();

export default _api;