let constants = {

    test:{
        key1:'value1'
    },

    managers: {
        'mykk':  [
            'shingo_sumitani',
            'shinya_tanaka',
            'yoshitake_bansyo',
            'hiromitsu_kozaka',
            'keini_niwa',
            'yasunobu_mori',
            'chihiro_maede',
            'satoe_mori',
            'yasuhiro_oota',
            'takahiro_nakayama',
            'tomotsune_mori',
            'shunji_tsuda',
            'riho_mori',
            'kiyotaka_manmoto',
        ],
        'daiwa':[]
    },
       
    improvementProposalAPIWfStatuses: {
        1: {
            title: "提案",
            value: 1,
        },
        3: {
            title: "上長承認",
            value: 3,
        },
        5: {
            title: "公開",
            value: 5,
        },
        6: {
            title: "対応中",
            value: 6,
        },          
        8: {
            title: "却下",
            value: 8,
        },
        9: {
            title: "完了",
            value: 9,
        },        

    }


};

module.exports = Object.freeze(constants);
