import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"system_maintenance",attrs:{"wrap":""}},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("グループメンテナンス")])]),_c(VContainer,[(_vm.loading)?_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"2"}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e()],1)],1)],1):_vm._e(),(!_vm.loading)?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Code")]),_c('th',{staticClass:"text-left"},[_vm._v("Name")])])]),_c('tbody',_vm._l((_vm.groupList),function(group){return _c('tr',{key:group.group_cd,on:{"click":function($event){return _vm.onSelectGroup(group)}}},[_c('td',[_vm._v(_vm._s(group.group_cd))]),_c('td',[_vm._v(_vm._s(group.name))])])}),0)]},proxy:true}],null,false,4017842867)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }