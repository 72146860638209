import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"system_maintenance",attrs:{"wrap":""}},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("グループ間参照設定")])]),_c(VContainer,[_c(VRow,[_c(VCol,[_vm._v(" 【 "+_vm._s(_vm.selectGroupName)+"】から追加で参照できるグループを選択してください。"),_c('br'),_vm._v(" （下位グループ、直上のグループは標準で参照可能です） ")])],1),(_vm.loading)?_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"2"}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e()],1)],1)],1):_vm._e(),(!_vm.loading && _vm.groupList.length === 0)?_c(VRow,[_c(VCol,[_vm._v(" 追加で選択可能なグループは存在しません ")])],1):_vm._e(),(!_vm.loading && _vm.groupList.length > 0)?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Code")]),_c('th',{staticClass:"text-left"},[_vm._v("Name")])])]),_c('tbody',_vm._l((_vm.groupList),function(group){return _c('tr',{key:group.group_cd},[_c('td',[_c(VCheckbox,{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","value":group.group_cd},model:{value:(_vm.viewableGroups),callback:function ($$v) {_vm.viewableGroups=$$v},expression:"viewableGroups"}})],1),_c('td',[_vm._v(_vm._s(group.name))])])}),0)]},proxy:true}],null,false,2888886842)}):_vm._e(),_c(VRow,[_c(VCol,[(_vm.groupList.length > 0)?_c(VBtn,{attrs:{"loading":_vm.updating},on:{"click":_vm.onSave}},[_vm._v("保存")]):_vm._e(),_c(VBtn,{on:{"click":_vm.onCancel}},[_vm._v("キャンセル")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }