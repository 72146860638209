<template>
  <v-container fluid class="timeline">
    <!-- <v-fade-transition mode="out-in"> -->
    <v-layout wrap>
      <!-- text-center -->

      <v-container
        class=""
        v-for="(dateData, idx) in list"
        :key="dateData.date + '_' + dateData.new_arrivals"
      >
        <h3>
          <span v-if="dateData.new_arrivals"
            ><v-icon class="mr-2" color="red lighten-1">fiber_new</v-icon></span
          >
          <span>{{ formatDateToHeaderDisplay(dateData.date) }}</span>
        </h3>

        <v-card
          v-for="userEntry in dateData.userEntries"
          :key="userEntry.staff_cd"
          class="pa-3 mt-3"
        >
          <v-list-item>
            <ProfileImageView :staff-cd="userEntry.staff_cd"></ProfileImageView>
            <v-list-item-content class="ml-3">
              <v-list-item-title class="headline">{{
                userEntry.user_name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                userEntry.group_name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-for="(entry, idx) in userEntry.entries">
            <!-- 次のエントリの読み込み用 -->
            <IntersectionObserver
              v-if="entry.isLastEntry"
              @intersect="onLastEntryIntersected(entry)"
            />

            <v-divider v-if="idx > 0" />

            <!-- 日報の表示 -->
            <ReportDetail :report-id="entry.reportId" />

            <!-- A,B共通のツールボックスとコメント-->
            <ReportComments :report-id="entry.reportId" />

            <IntersectionObserver
              v-if="useAutoRead && !entry.read_event_fired"
              @intersect="onEntryIntersected(entry)"
            />

            <!-- 転送の履歴 転送可能な人だけ表示 -->
            <ReportForwards :report-id="entry.reportId" />
          </template>
        </v-card>
      </v-container>

      <loading-view :loading="loading" />
    </v-layout>
  </v-container>
</template>

<script>
import Common from "@/components/Common";
import IntersectionObserver from "@/components/IntersectionObserver";

import TimelineAPI from "@/apis/TimelineAPI";
import ProfileImageView from "../components/controls/ProfileImageView";
import ReportViewSupport from "../components/ReportViewSupport";
import ReportComments from "./sub/ReportComments";
import ReportDetail from "./sub/ReportDetail";
import ReportForwards from "./sub/ReportForwards";

export default {
  mixins: [Common, ReportViewSupport],
  components: {
    IntersectionObserver,
    ProfileImageView,
    ReportComments,
    ReportDetail,
    ReportForwards,
  },
  mounted: function () {
    this.setupConditions();
  },
  destroyed() {},
  props: [],
  watch: {
    $route: function (to, from) {
      this.setupConditions();
    },
  },
  data() {
    return {
      loading: false,
      list: [
        {
          date: "2019年8月20日",
          new_arrivals: true,

          userEntries: [
            {
              staff_cd: "",
              user_name: "森康 太郎",
              group_name: "改革室",
              name_initial: "TM",
              entries: [],
            },
          ],
        },
      ],

      lastIndex: "",
      // -- コメント情報
      commentDlgDisplay: false,
      commentDlgData: {
        entity: {},
        content: "",
        comment_id: null,
      },
      // -- リプライ情報
      replyDlgDisplay: false,
      replyDlgData: {
        entity: {},
        content: "",
        reply_id: null,
      },
      emoteModel: null,
      // -- 人へ転送
      forwardDlgDisplay: false,
      forwardDlgData: {
        staff_cd: "",
        entity: {},
        isValid: false,
      },
      forwardCommentRules: [(v) => !!v || "コメントは必須項目です"],
    };
  },
  methods: {
    setupConditions() {
      this.list.splice(0);
      this.fetchData(true);
    },

    fetchData(needDataFetch) {
      let self = this;

      if (!this.isInitialized) {
        this.fetchBasicData(function () {
          self.fetchData(needDataFetch);
        });
        return;
      }

      if (needDataFetch) {
        this.list.splice(0);
        this.lastIndex = "";
        this.fetchNext();
      }
    },

    fetchNext() {
      let self = this;
      this.loading = true;

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return TimelineAPI.listMyEntries(this.lastIndex);
        })
        .then((response) => {
          self.makeDisplayData(response.data);
          self.loading = false;
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
          self.showErrorToast("データの取得に失敗しました。");
        });
    },

    fetchBasicData(finishProc) {
      let self = this;

      self.isInitialized = true;
      if (finishProc) {
        finishProc();
      }
    },

    //--------------------------------------------
    // 表示用データ生成
    //--------------------------------------------

    makeDisplayData(data) {
      let self = this;

      //console.log("home makeDisplayData", data);
      //if (data.forwards && data.forwards.length > 0) {
      //  console.log("forwards", data.forwards);
      //}

      let dateRecords = data.records;
      this.lastIndex = data.lastIndex;

      // 基本的には後ろに追加していく
      // でも日付レコードが存在していたらそのレコードに追加する

      let lastEntry = null;

      dateRecords.forEach((srcDateRec) => {
        let isNew = srcDateRec.isNew;
        let reportDate = srcDateRec.dt;

        //console.log("date: " + reportDate);

        let dateRec = {
          date: reportDate, //"2019年8月20日",
          new_arrivals: isNew,
          userEntries: [],
        };

        srcDateRec.records.forEach((srcUserRec) => {
          //console.log("  user: " + srcUserRec.user.staff_cd);
          let userRec = {
            staff_cd: srcUserRec.user.staff_cd,
            user_name: srcUserRec.user.name,
            group_cd: srcUserRec.user.group_cd,
            group_name: this.getGroupName(srcUserRec.user.group_cd),
            name_initial: "HK",
            entries: [],
          };

          let canEdit = srcUserRec.user.staff_cd === self.account.staff_cd;

          srcUserRec.records.forEach((entrySet) => {
            let reportId = entrySet.report.id;

            console.log("reportId " + reportId, entrySet);

            let reportEntry = self.makeReportDisplayData(entrySet);

            // vuexに保存
            self.$store.commit("setReport", reportEntry);

            // entriesにはキー情報 + 制御情報を保持する
            let needReadEvent =
              !reportEntry.already_read || reportEntry.updated;
            let entry = {
              reportId: reportId,
              read_event_fired: !needReadEvent,
              isLastEntry: false,
            };
            lastEntry = entry;

            userRec.entries.push(entry);
          });

          dateRec.userEntries.push(userRec);
        });

        // 同じ日付&新着フラグが存在しているか？
        if (this.list.length > 0) {
          let lastDateRec = this.list[this.list.length - 1];
          if (
            lastDateRec.date === reportDate &&
            lastDateRec.new_arrivals === isNew
          ) {
            dateRec.userEntries.forEach((a) => lastDateRec.userEntries.push(a));
            dateRec = null;
          }
        }

        if (dateRec) {
          this.list.push(dateRec);
        }
      });

      if (lastEntry) {
        lastEntry.isLastEntry = true;
      }
    },

    //--------------------------------------------
    // 既読処理
    //--------------------------------------------

    onEntryIntersected(entry) {
      let self = this;

      let reportEntry = this.reportMap[entry.reportId];

      // 既読設定を行う
      if (!!reportEntry && this.useAutoRead) {
        if (!reportEntry.cancel_auto_read) {
          if (!reportEntry.already_read || reportEntry.updated) {
            this.setAlreadyRead(reportEntry.id);
          }

          setTimeout(function () {
            self.$store.commit("setReportRead", {
              reportId: entry.reportId,
            });
          }, 1000);
        }

        reportEntry.read_event_fired = true;
      }
    },

    onLastEntryIntersected(reportEntry) {
      // 最後のエントリーが表示されたら次のエントリーを読み込む
      if (reportEntry.isLastEntry) {
        reportEntry.isLastEntry = false;
        this.fetchNext();
      }
    },
  },
};
</script>
