import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('loading-view',{attrs:{"loading":_vm.loading}}),(!_vm.loading)?[_c(VListItem,[_c('ProfileImageView',{attrs:{"staff-cd":_vm.userEntry.staff_cd}}),_c(VListItemContent,{staticClass:"ml-3"},[_c(VListItemTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.userEntry.user_name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.userEntry.group_name))])],1)],1),_c('ReportDetail',{attrs:{"report-id":_vm.reportId}}),_c('ReportComments',{attrs:{"report-id":_vm.reportId}}),_c('ReportForwards',{attrs:{"report-id":_vm.reportId}})]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }