<template>
  <v-container class="daily-view">
    <div><span class="title"></span></div>

    <v-row dense>
      <v-col cols="12" sm="6" md="3" lg="3">
        <DateInputField v-model="selectedDate" label="検索日"></DateInputField>
      </v-col>
    </v-row>

    <v-btn v-on:click="fetchData" class="primary">検索</v-btn>

    <v-divider class="mt-4"></v-divider>

    <v-row v-for="groupRec in records" :key="groupRec.group.group_cd">
      <v-col cols="12" class="title">{{ groupRec.group.name }}</v-col>

      <v-col
        v-for="staffRec in groupRec.staffs"
        :key="staffRec.staff.staff_cd"
        cols="6"
        xs="6"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          min-height="160"
          :color="staffRec.isUseSFA ? 'blue-grey lighten-4' : '#FDFDFD'"
        >
          <!-- grey lighten-5 -->
          <v-card-text>
            <div>
              <span>{{ staffRec.staff.name }}</span>
              <span v-if="staffRec.isUseSFA" class="ml-2">(SFA)</span>
            </div>

            <div
              v-for="(report, index) in staffRec.reports"
              :key="report.report_id"
            >
              <v-divider v-if="index > 0" class="mt-2"></v-divider>
              <a v-on:click.prevent.stop="onSelectReport(report)">
                <p class="subtitle-1 text--primary">
                  <span v-if="report.title">{{ report.title }}</span>
                  <span v-else>(無題)</span>
                </p>
              </a>
              <v-chip
                small
                v-if="!report.isAlreadyRead"
                color="amber"
                text-color="black"
                class="chip"
                >未読</v-chip
              >
              <v-chip
                small
                v-if="report.isAlreadyRead && report.isUpdated"
                color="green"
                text-color="white"
                class="chip"
                >更新</v-chip
              >
              <v-chip
                small
                v-if="report.isAlreadyRead && !report.isUpdated"
                class="chip"
                >既読</v-chip
              >

              <v-chip
                class="report_attr"
                small
                v-if="report.goodOrBad === 'B'"
                color="red darken-1"
                text-color="white"
                >BadNews</v-chip
              >
              <v-chip
                class="report_attr"
                small
                v-if="report.goodOrBad === 'G'"
                color="blue darken-1"
                text-color="white"
                >GoodNews</v-chip
              >

              <v-chip
                class="report_attr"
                small
                v-if="report.importance === '1'"
                color="blue darken-1"
                text-color="white"
                >重要</v-chip
              >
              <v-chip
                class="report_attr"
                small
                v-if="report.importance === '2'"
                color="orange darken-1"
                text-color="white"
                >最重要</v-chip
              >
              <v-chip
                class="report_attr"
                small
                v-if="report.importance === '3'"
                color="red darken-1"
                text-color="white"
                >緊急!</v-chip
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading-view :loading="loading" />
  </v-container>
</template>

<script>
import moment from "moment";
import Common from "@/components/Common";
import DailyViewAPI from "@/apis/DailyViewAPI";
import DateInputField from "../components/controls/DateInputField";

export default {
  mixins: [Common],
  components: {
    DateInputField,
  },
  mounted: function () {
    let m = moment();
    let h = m.hour();

    if (h < 17) {
      m.subtract(1, "days");
    }

    this.selectedDate = m.format("YYYY-MM-DD");

    this.fetchData();

    // イベントハブのリッスンを開始
    this.$eventHub.$off("report_read", this.onReportRead);
    this.$eventHub.$on("report_read", this.onReportRead);

    this.$eventHub.$off("report_unread", this.onReportUnread);
    this.$eventHub.$on("report_unread", this.onReportUnread);
  },
  destroyed() {
    this.$eventHub.$off("report_read", this.onReportRead);
    this.$eventHub.$off("report_unread", this.onReportUnread);
  },
  watch: {
    //'month':function (to, from) {
    //    this.makeData()
    //},
  },
  data() {
    return {
      loading: false,
      selectedDate: "",

      records: [],
    };
  },
  computed: {},
  methods: {
    fetchData() {
      let self = this;

      if (!this.selectedDate) {
        return;
      }

      this.loading = true;

      this.records.splice(0);

      let dateStr = moment(this.selectedDate).format("YYYY-MM-DD");

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return DailyViewAPI.getData(dateStr);
        })
        .then((a) => {
          this.makeData(a.data);

          this.loading = false;
        })
        .catch(function (err) {
          self.loading = false;
          console.log(err);
          self.showErrorToast("データの取得に失敗しました。");
        });
    },

    makeData(responseData) {
      console.log(responseData);

      this.records.splice(0);

      let groupRecords = responseData;

      groupRecords.forEach((groupRecord) => {
        this.records.push(groupRecord);
      });
    },

    onSelectReport(report) {
      this.reportDialogRef.open(report.report_id, {});
    },

    onReportRead(data) {
      let reportId = data.reportId;

      //console.log("onReportRead " + reportId);

      this.records.forEach((groupRec) => {
        groupRec.staffs.forEach((staffRec) => {
          staffRec.reports.forEach((report) => {
            if (report.report_id == reportId) {
              report.isAlreadyRead = true;
              report.isUpdated = false;
            }
          });
        });
      });
    },

    onReportUnread(data) {
      let reportId = data.reportId;

      //console.log("onReportRead " + reportId);

      this.records.forEach((groupRec) => {
        groupRec.staffs.forEach((staffRec) => {
          staffRec.reports.forEach((report) => {
            if (report.report_id == reportId) {
              report.isAlreadyRead = false;
              report.isUpdated = false;
            }
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
