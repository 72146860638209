import Vue from 'vue'
import ImprovementProposalAPI from '@/apis/ImprovementProposalAPI'

// 改善提案系STORE
// → 廃止！

const getDefaultState = () => ({
    message: '',
    count: 0,
    myUserId:"",
    proposalIds:[],
    recordMap:{},   
  })

const state = {
    myUserId: "",
    fetched:false,
    proposalIds:[],
    recordMap: {
        1111 : {
            id: 1111,
            title: "",
            content: "会議室が熱く・・・・・・・・・ああああああ",
            create_at: "2023-08-02",
            post_user_id: "12121fdfee",
            post_staff_cd: "191",
            post_user_name: "aaaaaaa",
            is_unread: true,            
            read_event_fired: false,
            comments: [
                {
                    id: 12,
                    content: "良い提案だと思います。",
                    post_at: "2023-08-02",
                    post_user_id: "12121fdfee",
                    post_staff_cd: "191",
                    post_user_name: "吉田怜史", 
                }
            ]
        },  
    },   
    name: 'Mory',
    message: '',
};
const getters = {
    message:state =>state.message,
    name: state => state.name,
    myRecords: state => {
        let result = [];
        state.proposalIds.forEach(propId => {
            let d = state.recordMap[propId];
            if (d.post_user_id == state.myUserId) {
                result.push(propId)
            }
        })
        return result;
    },
};
const mutations = {
    reset(state) {
        Object.assign(state, getDefaultState())
    },
    setMyUserId(state,userId){
        state.myUserId = userId;
        console.log("setMyUserId",userId)
    },
    updateMessage(state,newMessage){
        state.message = newMessage
    },
    updateName(state, newName) {        
        state.name = newName
    },
    appendProposal(state, payload) { 
        let id = payload.id;

        if (state.proposalIds.indexOf(id) == -1) {
            state.proposalIds.push(id);    
        }

        // mapにproposalIdをキーとして保存
        Vue.set(state.recordMap, id, payload.data)
        console.log("appendProposal " + id, payload.data)
               
    },
    replaceProposal(state, payload) { 
        let id = payload.id;

        // mapにproposalIdをキーとして保存(置き換え)
        Vue.set(state.recordMap, id, payload.data)
        console.log("appendProposal " + id,payload.data)
    },
    remove(state, id) {     
        // 改善提案レコードの削除        
        let idx = state.proposalIds.indexOf(id);
        if (idx > -1) {
            state.proposalIds.splice( idx, 1);
        }

        console.log("store.remove called " + id);
        Vue.delete(state.recordMap, id);
    },
    sortProposal(state, payload) { 
        
        state.proposalIds.sort(function(val1, val2) {
            return val1 < val2 ? 1 : -1;
        })
        console.log("sortedlist",state.proposalIds)

    },
};
const actions = {
    // fetch
    // store
    // delete
    // remove
    // get
    // put
    // append


    async fetchList({ commit, dispatch, state }, payload) {

        const fetchProp = async function (proposalId) {
            let data = await dispatch('_fetchProposalDetail', proposalId);
            commit('appendProposal', {
                id: proposalId,
                data: data,
            });                
        }

        try{
            let idList = await ImprovementProposalAPI.list();
            console.log("fetchList", idList);
            /*
            idList.forEach(async proposalId => {
                let data = await dispatch('_fetchProposalDetail', proposalId);
                commit('appendProposal', {
                    id: proposalId,
                    data: data,
                });                
            });
            */
            await Promise.all(idList.map(proposalId => fetchProp(proposalId)));
            
            commit('sortProposal', {});
            state.fetched = true;
            return state.proposalIds;
        } catch(e) {
            console.error(e);
        }
        return []
    },

    async fetchProposal({ commit, dispatch, state }, proposalId) {
        let data = await dispatch('_fetchProposalDetail', proposalId);
        commit('appendProposal', {
            id: proposalId,
            data: data,
        });
        commit('sortProposal', {});
        
        return data;
    },

    async refreshProposal({ commit, dispatch, state }, proposalId) {
        let data = await dispatch('_fetchProposalDetail', proposalId);
        commit('replaceProposal', {
            id: proposalId,
            data: data,
        });
        return data;
    },

    async deleteProposal({ commit, dispatch, state }, proposalId) {
        await ImprovementProposalAPI.deleteProposal(proposalId);
        console.log("deleteProposal" + proposalId); 

        commit('remove',proposalId);
    },


    async _fetchProposalDetail({ commit, dispatch, state }, proposalId) {
        let d = await ImprovementProposalAPI.getProposal(proposalId);
        console.log("fetchProposalDetail" + proposalId, d);
        return convertApiResultToState(d);
    },

    // 既読にする
    async makeAsRead({ commit, dispatch, state }, proposalId) {
        
        console.log("setRead" + proposalId); 
        state.recordMap[proposalId].is_unread = true;     
        await ImprovementProposalAPI.readProposal(proposalId);
    },




    updateMessage({commit},newMessage){
        commit('updateMessage',newMessage);
    },
    updateName({commit},newName){
        commit('updateName',newName)
    },    
};

const convertApiResultToState = function (apiRes) {
    let comments = apiRes.comments.map(r => convertCommentApiResultToState(r));
    return {
        id: apiRes.id,
        title: apiRes.title,
        content: apiRes.content,
        state: apiRes.state,
        workflowState: apiRes.workflowState ,
        groupCd:apiRes.groupCd,
        create_at: apiRes.createAt,
        post_user_id: apiRes.postUserId,
        post_staff_cd: "",
        postUserName: apiRes.postUserName,
        viewStatus: apiRes.viewed , // 0:未読 1:既読 3:更新
        is_unread: apiRes.viewed == 0,            
        read_event_fired: false,
        comments: comments,
    }
}


const convertCommentApiResultToState = function (apiRes) {
    return {
        id: apiRes.commentId,
        content: apiRes.content,        
        commentAt: apiRes.commentAt,
        commentUserId: apiRes.commentUserId,
        commentUserName: apiRes.commentUserName,
        isLatest: apiRes.isLatest,
        groupCd: apiRes.groupCd,
        groupChanged:apiRes.groupChanged,
        workflowState: apiRes.workflowState,
        workflowStateChanged: apiRes.workflowStateChanged,        
        contentForDisplay: splitComment(apiRes.content),
        canEdit:apiRes.canEdit,
    }
}


// コメントの内容をテキスト、URLで分割します。
const splitComment = function(text) {
    let analyzeResult = [];

    let regexp_url = /(https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+)/g; // ']))/;
    let matchResult = text.match(regexp_url);

    let wkText = text;
    if (matchResult) {
        matchResult.forEach((url) => {
        let urlLength = url.length;
        let startIndex = wkText.indexOf(url);
        if (startIndex > -1) {
            // startIndex の位置までを文字列として切り出す
            let t1 = "";
            if (startIndex > 0) {
            t1 = wkText.substr(0, startIndex);
            }

            // url以降を文字列として切り出す
            let afterIndex = startIndex + urlLength;
            let t2 = wkText.substr(afterIndex);

            analyzeResult.push({
            type: "text",
            value: t1,
            });
            analyzeResult.push({
            type: "url",
            value: url,
            });

            wkText = t2;
        }
        });
    }

    if (wkText) {
        analyzeResult.push({
        type: "text",
        value: wkText,
        });
    }

    return analyzeResult;
}

 
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}


// MutationとActionの違い
// Mutationは非同期処理に非対応、Actionは非同期処理にも対応
// MutationはStateの変更
// Actionは動作を低値
