<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    @keydown.esc="cancel"
    content-class="user-select-dialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline">ユーザ選択</span>
      </v-card-title>

      <v-card-text class="body-1 pt-5 black--text">
        <v-text-field
          v-model="userFilter"
          label="名前、担当者CDを入力"
          clearable
          autocomplete="off"
        />

        <v-container style="min-height: 100px">
          <v-row dense>
            <v-col
              cols="4"
              v-for="(item, index) in userList"
              :key="item.user_id"
            >
              <span
                ><a class="staff-name" v-on:click.prevent="selectUser(item)">{{
                  item.name
                }}</a></span
              >
            </v-col>
            <v-col cols="4" v-if="isOmitted">・・省略</v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">キャンセル</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BasicInfoAPI from "@/apis/BasicInfoAPI";
import constants from "@/constants.js";
export default {
  components: {},
  props: [],
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,

      // 選択結果
      userId: "",
      staffCd: "",
      userName: "",

      // フィルタ条件
      userFilter: "",

      // ユーザのリスト
      userListAll: [],
      userList: [],

      isOmitted: false,

      options: {},
    };
  },
  watch: {
    userFilter: function () {
      this.applyFilter();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  methods: {
    open(filter, options) {
      this.dialog = true;
      this.userFilter = filter;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },

    fetchData() {
      let self = this;
      this.userListAll.splice(0);
      BasicInfoAPI.listUser().then((a) => {
        let sortedList = a.data.sort(function (a, b) {
          return a.staff_cd < b.staff_cd ? -1 : 1;
        });

        sortedList.forEach((b) => {
          self.userListAll.push(b);
        });
        self.userFilter = "";
        self.applyFilter();
      });
    },

    applyFilter() {
      let self = this;
      this.userList.splice(0);
      this.isOmitted = false;

      let filter = this.userFilter;
      if (filter) {
        this.userListAll
          .filter((a) => {
            return a.name.indexOf(filter) > -1 || a.staff_cd == filter;
          })
          .forEach((b) => {
            self.userList.push(b);
          });
      } else {
        // 未入力の時は役職者をリストする
        let company = process.env.VUE_APP_COMPAMY_CD;
        let managers = constants["managers"][company];
        //console.log("company", company);
        //console.log("managers", managers);
        let managersSet = new Set(managers ?? []);
        //console.log("this.userListAll", this.userListAll);
        this.userListAll
          .filter((a) => {
            //if (a.title_cd) {
            //  let b = a.title_cd.slice(0, 1);
            //    return b >= "A" && b <= "Z";
            //} else return false;
            return managersSet.has(a.user_id);
          })
          .forEach((b) => {
            self.userList.push(b);
          });

        //this.userListAll.forEach(b => {self.userList.push(b) });
        //if (self.userList.length > 10){
        //    self.userList.splice(10);
        //    self.isOmitted = true;
        //}
      }
    },

    selectUser(user) {
      this.userId = user.user_id;
      this.staffCd = user.staff_cd;
      this.userName = user.name;

      this.resolve({
        userId: user.user_id,
        staffCd: user.staff_cd,
        userName: user.name,
        titleCd: user.title_cd,
      });

      this.dialog = false;
    },

    onClickClear() {
      this.userFilter = "";
      this.userId = "";
      this.staffCd = "";
    },
  },
};
/*
// 呼び出し方法
// https://qiita.com/ta1nakamura/items/f844dd2437af8ecc5674
async onClickOpen(){
  console.log('--onClickOpen')
  if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
    console.log('--yes')
  }else{
    console.log('--no')
  }
}
* */
</script>
