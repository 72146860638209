import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onCommentDlgCancelClick.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"popup-header"},[_c('span',{staticClass:"headline"},[_vm._v("返信")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VTextarea,{attrs:{"counter":"","label":"返信","required":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1),_c(VCardActions,[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!!this.replyId),expression:"!!this.replyId"}],attrs:{"color":"secondary","text":""},nativeOn:{"click":function($event){return _vm.onCommentDlgDeleteClick.apply(null, arguments)}}},[_vm._v("削除")]),_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.onCommentDlgSaveClick.apply(null, arguments)}}},[_vm._v("保存")]),_c(VBtn,{attrs:{"color":"grey lighten-2"},nativeOn:{"click":function($event){return _vm.onCommentDlgCancelClick.apply(null, arguments)}}},[_vm._v("キャンセル")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }