<script>
import { mapState } from "vuex";

import NotificationAPI from "../apis/NotificationAPI";

export default {
  mixins: [],
  mounted: function () {},

  data() {
    return {};
  },
  computed: {
    ...mapState(["notifications", "notification_count"]),
  },
  methods: {
    fetchNotifications() {
      let self = this;
      return Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => NotificationAPI.list())
        .then((response) => {
          let notificationList = response.data;
          console.log("fetchNotifications", notificationList);
          self.$store.commit("setNotifications", notificationList);
        });
    },

    notificationSetRead(notice) {
      // クリックしたお知らせを既読とする
      if (notice.state == 0) {
        this.$store.dispatch("readNotification", notice.noticeId);
        return Promise.resolve()
          .then((a) => {
            return this.checkAuthToken();
          })
          .then((a) => NotificationAPI.putAlreadyRead(notice.noticeId));
      }
    },

    notificationListSetRead(noticeList) {
      if (!noticeList) return;

      let self = this;

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          noticeList.forEach((notice) => {
            self.$store.dispatch("readNotification", notice.noticeId);
            NotificationAPI.putAlreadyRead(notice.noticeId);
          });
        });
    },
  },
};
</script>
