<template>
  <v-container class="entry" wrap>
    <!-- <v-fade-transition mode="out-in"> -->
    <v-layout>
      <!-- text-center -->
      <v-flex>
        <v-form>
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="3">
              <DateInputField
                v-model="reportDate"
                label="作業日"
                @input="onReportDateChanged"
              ></DateInputField>
            </v-col>
          </v-row>
        </v-form>

        <v-container v-if="loading" fluid fill-height>
          <v-row align="center" justify="center">
            <!--<v-col cols="auto"></v-col>-->
            <v-col cols="2">
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <!--<v-col cols="auto"></v-col>-->
          </v-row>
        </v-container>

        <v-container v-if="!loading">
          <v-row>
            <v-col>
              <v-sheet class="pa-3">
                <span class="title font-weight-bold red--text darken-4"
                  >『悪い情報ほど、いち早く』</span
                >
                <br />
                <span class="subtitle-1 font-weight-bold pl-5"
                  >BadNews＝GoodNews</span
                >
              </v-sheet>

              <v-sheet v-if="isMoriyasu" class="pt-0 pb-0 pl-5 pr-2">
                <ul class="body-2">
                  <li>
                    悪い情報は、真っ先に上司に報告します。（判明後、２時間以内の報告が目安です）
                  </li>
                  <li>悪い情報を、翌日に持ち越す事は許されません。</li>
                  <li>
                    上司に報告した悪い情報は、日報にも記載し社内に共有します。
                  </li>
                  <li>
                    日報に悪い情報を記載する際は、誰（上司）に報連相した（する）のかも合わせて記載します。
                  </li>
                  <li>
                    未確定の段階でも、可能性があると判断すれば、早い報告を心掛けます。(後で間違いであっても良しとする）
                  </li>
                  <li>
                    ＯＶＣが発生する発生しないに関係なく、悪い情報だと判断すれば、報告します。(当社への評判など）
                  </li>
                  <li>
                    悪い情報以外でも、業務における気付きや、感じたことを日報に記載します。
                  </li>
                </ul>
              </v-sheet>
            </v-col>
          </v-row>

          <v-tabs v-model="selected_tab">
            <v-tab>標準</v-tab>
            <!--
            <v-tab>
              <span v-if="todoCount === 0">定型業務</span>
              <v-badge v-else color="green">
                <template v-slot:badge>
                  <span>{{todoCount}}</span>
                </template>
                <span>定型業務</span>
              </v-badge>
            </v-tab>
            -->
          </v-tabs>
          <v-tabs-items v-model="selected_tab">
            <!-- 標準 -->
            <v-tab-item :value="0" class="pa-5">
              <v-container>
                <v-simple-table :dense="false" :fixed-header="false">
                  <thead>
                    <tr>
                      <th style="width: 5rem">状態</th>
                      <th>時間</th>
                      <th>タイトル/内容</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(entry, idx) in standardReportList"
                      v-on:click="showStandardEditDialog(idx)"
                    >
                      <td>
                        <span v-if="entry.state == 1">公開</span>
                        <span v-if="entry.state == 2">下書</span>
                      </td>
                      <td>
                        <span v-if="entry.all_day">終日</span>
                        <span v-if="!entry.all_day">
                          {{ entry.start_time }} 〜 {{ entry.end_time }}
                        </span>
                      </td>
                      <td>
                        <div v-if="entry.title">【 {{ entry.title }} 】</div>
                        <div>{{ entry.omitted_content }}</div>
                      </td>
                      <!--<td>
                    <a v-on:click.prevent="onDeleteBtnClick(idx)" class="ml-3"><v-icon>delete</v-icon></a>
                  </td>-->
                    </tr>
                  </tbody>
                </v-simple-table>

                <v-btn @click="onNewEntryClick" color="primary" class="mt-6">
                  新規登録
                </v-btn>
              </v-container>
            </v-tab-item>

            <!-- 定型 -->
            <v-tab-item :value="1">
              <v-container v-if="!todoList || todoList.length === 0">
                <p>予定されている業務はありません。</p>
              </v-container>
              <v-container v-if="!!todoList && todoList.length > 0">
                <template v-for="item in todoList">
                  <v-card outlined>
                    <v-container dense class="pb-0">
                      <v-row dense>
                        <v-col cols="3">
                          {{ item.title }}
                          <br /><span class="overline"
                            >予定日:{{ item.scheduleDate }}</span
                          >
                        </v-col>
                        <v-col>
                          <v-container dense class="pb-0">
                            <v-row>
                              <v-radio-group
                                class="narrow-padding"
                                v-model="item.state"
                                row
                                dense
                                single-line
                              >
                                <v-radio label="未処理" :value="0" />
                                <v-radio label="処理済" :value="1" />
                                <v-radio label="処理しない" :value="2" />
                              </v-radio-group>
                            </v-row>
                            <v-row>
                              <v-text-field
                                v-model="item.note"
                                dense
                                single-line
                                class="narrow-padding"
                                placeholder="コメント"
                                maxlength="50"
                              ></v-text-field>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </template>

                <v-row>
                  <v-col>
                    <v-textarea label="メモ" v-model="todoComment"></v-textarea>
                  </v-col>
                </v-row>

                <v-btn @click.prevent="onSaveTodoBtnClick" color="primary">
                  更新
                </v-btn>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Common from "@/components/Common";
import ReportEntryAPI from "@/apis/ReportEntryAPI";
import DateInputField from "../components/controls/DateInputField";
import TimeInputField from "../components/controls/TimeInputField";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import RoleSupport from "@/components/RoleSupport";

export default {
  mixins: [Common, RoleSupport],
  components: {
    DateInputField,
    TimeInputField,
    ConfirmDialog,
  },
  mounted: function () {
    this.standardReportList.splice(0);
    this.todoList.splice(0);

    this.setupConditions();
  },
  props: ["propReportDate"],
  watch: {
    $route: function (to, from) {
      this.setupConditions();
    },
  },
  data() {
    return {
      selected_tab: 0,
      menu: false,
      menu2: false,
      menu3: false,

      reportDate: "",

      loading: false,

      // 標準
      standardReportList: [
        {
          report_type: "A",
          dt: null,
          start_time: "終日",
          end_time: "",
          title: "終日作業のタイトル",
          content: "",
          omitted_content: "",
          staff_cd: "",
          tags: ["顧客訪問", "生産計画"],
          all_day: true,
          state: 0,
          extraContents: [],
        },
      ],

      // 拡張
      extraReportList: [
        {
          report_type: "C",
          dt: null,
          start_time: "終日",
          end_time: "",
          title: "終日作業のタイトル",
          content: "",
          omitted_content: "",
          staff_cd: "",
          tags: ["顧客訪問", "生産計画"],
          all_day: true,
          state: 0,
        },
      ],

      // 定型業務
      todoCount: 0,
      todoList: [
        {
          report_type: "B",
          dt: null,
          title: "小松金沢の受注処理",
          state: "0",
          note: "",
        },
        {
          report_type: "B",
          dt: null,
          title: "定型業務２",
          state: "0",
          note: "",
        },
      ],
      todoComment: "",

      customers: ["小松ss粟津工場", "室戸鉄工所", "ダイワ"],

      staffs: ["丹羽常務", "田中課長", "番匠課長"],

      editDialog: false,
      editData: {
        sourceIdx: 0,
        report_type: "A",
        dt: null,
        start_time: null,
        end_time: null,
        title: "",
        content: "",
        staff_cd: "",
        tags: ["顧客訪問", "生産計画"],
        all_day: false,

        inputTag: "",
      },

      userFilter: "",

      leaveTime: "",

      isInitialized: true,
      isFetched: false,
      fetchedDate: null,
    };
  },
  computed: {
    canSetLeaveTime() {
      if (!this.reportDate) {
        return false;
      } else {
        let a = this.formatDate(this.parseDateTime(new Date()), "YYYY-MM-DD");
        let b = this.formatDate(
          this.parseDateTime(this.reportDate),
          "YYYY-MM-DD"
        );

        return a === b;
      }
    },
  },
  methods: {
    setupConditions() {
      if (this.propReportDate != null) {
        console.log("propReportDate=" + this.propReportDate);
        this.reportDate = this.propReportDate;
      } else {
        console.log("propReportDate is null");
        this.reportDate = this.formatDateToComponentValue(
          new Date().toISOString()
        );
      }

      //if (this.fetchedDate != this.reportDate){
      //  this.fetchData(true);
      //}

      this.fetchData(true);
    },

    fetchData(needDataFetch) {
      let self = this;

      if (!this.isInitialized) {
        this.fetchBasicData(function () {
          self.fetchData(needDataFetch);
        });
        return;
      }

      let reportDate = this.reportDate;

      if (needDataFetch && !!reportDate) {
        this.loading = true;

        this.leaveTime = "";

        Promise.resolve()
          .then((a) => {
            return this.checkAuthToken();
          })
          .then((a) => {
            //return Promise.all([
            //  ReportEntryAPI.list(reportDate),
            //  //ReportEntryAPI.tasks(reportDate)
            //]);
            return ReportEntryAPI.list(reportDate).then((response) => {
              self.makeDisplayData(response.data, reportDate);
              self.fetchedDate = reportDate;
            });
          })
          .then((a) => {
            self.loading = false;
          })
          .catch(function (err) {
            console.log(err);
            self.loading = false;
            self.showErrorToast("データの取得に失敗しました。");
          });
      }
    },

    makeDisplayData(data, reportDate) {
      //console.log(data1);

      let self = this;

      let listA = data.listA;
      let entryB = data.entryB;
      let todoList = data.todoList;
      let listC = data.listC;

      this.standardReportList.splice(0);
      this.todoList.splice(0);

      // 標準日報のリストを作成
      listA.forEach((rec) => {
        //console.log(rec);

        let report = rec.report;

        let displayRec = {
          id: report.id,
          report_type: "A",
          dt: report.dt,
          start_time: report.start_time,
          end_time: report.end_time,
          title: report.title,
          content: report.content,
          omitted_content: "",
          staff_cd: report.staff_cd,
          all_day: report.all_day,
          state: report.state,
          extraContents: [],
        };

        let content = "";

        let extraContents = rec.extraContents;
        extraContents.forEach((ec) => {
          displayRec.extraContents.push({
            content_no: ec.content_no,
            content_type: ec.content_type,
            content: ec.content,
          });

          content += ec.content;
        });

        content += report.content;

        displayRec.omitted_content = self.omitContents(content);

        self.standardReportList.push(displayRec);
      });

      // 定型業務のリストを作成
      let savedTasks = [];
      if (entryB != null) {
        this.todoComment = entryB.report.content;
        savedTasks = entryB.todo;
      } else {
        this.todoComment = "";
      }

      todoList.forEach((rec) => {
        let todo = rec.todo;
        let days = rec.days;
        let weeks = rec.weeks;
        let dows = rec.dows;

        let displayRec = {
          id: todo.id,
          report_type: "B",
          dt: reportDate,
          scheduleDate: rec.scheduleDate,
          title: todo.name,
          state: 0,
          note: "",
        };

        let savedRec = savedTasks.find((v) => v.todo_id === todo.id);
        if (savedRec) {
          displayRec.state = savedRec.status;
          displayRec.note = savedRec.comment;
        }

        self.todoList.push(displayRec);
      });

      this.todoCount = self.todoList.length;
    },

    fetchBasicData(finishProc) {
      let self = this;

      self.isInitialized = true;
      if (finishProc) {
        finishProc();
      }
    },

    onReportDateChanged() {
      if (this.propReportDate != this.reportDate) {
        let path = this.$router.currentRoute.path;
        let url = this.buildUrl(path, { dt: this.reportDate });
        this.$router.replace(url);
      }
    },

    omitContents(t) {
      let len = 60;

      if (t) {
        if (t.length > len) {
          return t.replace(/[\n\r]/g, " ").substring(0, len) + "...";
        } else {
          return t;
        }
      } else {
        return "";
      }
    },

    showStandardEditDialog(idx) {
      let record = this.standardReportList[idx];

      let url = this.buildUrl("/entry_standard", {
        id: record.id,
        dt: this.reportDate,
      });
      this.$router.push(url);
    },

    onNewEntryClick() {
      let url = this.buildUrl("/entry_standard", {
        id: 0,
        dt: this.reportDate,
      });
      this.$router.push(url);
    },

    onAddTagBtnClick() {
      if (this.editData.inputTag) {
        this.editData.tags.push(this.editData.inputTag);
      }
    },
    onImportantBtnClick() {
      let tag = "重要";

      if (this.editData.tags.indexOf(tag) === -1) {
        this.editData.tags.push(tag);
      }
    },

    onDeleteTag(idx) {
      this.editData.tags.splice(idx);
    },

    onSaveTodoBtnClick() {
      let self = this;

      let staff_cd = this.account.staff_cd;
      let reportDate = this.reportDate;

      let todoData = this.todoList.map((a) => {
        return {
          todo_id: a.id,
          state: a.state,
          note: a.note,
        };
      });

      let postData = {
        report_type: "B",
        dt: reportDate,
        start_time: null,
        end_time: null,
        title: "",
        content: self.todoComment,
        staff_cd: staff_cd,
        tags: [],
        all_day: 1,
        todo: todoData,
      };

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return ReportEntryAPI.update(postData, self.propReportId);
        })
        .then((a) => {
          console.log("日報を更新しました。");
          self.showMessageToast("日報を保存しました。");
        })
        .catch(function (err) {
          console.log(err);
          self.showErrorToast("データの更新に失敗しました。");
        });
    },
  },
};
</script>

<style lang="scss"></style>
