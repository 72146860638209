<template>
  <v-container class="daily-view" ref="dailyviewcontainer" fill-height>
    <div><span class="title">入力チェック</span></div>

    <v-row dense>
      <v-col cols="12" sm="6" md="3" lg="3">
        <DateInputField v-model="selectedDate" label="検索日"></DateInputField>
      </v-col>
    </v-row>

    <v-row dense>
      <v-btn v-on:click="fetchData" class="primary mr-3">検索</v-btn>
      <v-btn v-on:click="onDownloadClick" :loading="downloading"
        >ダウンロード</v-btn
      >
    </v-row>

    <v-row ref="dailyviewtable" dense>
      <v-simple-table fixed-header :height="tableHeight" v-if="!loading">
        <!--  fixed-header height="300px" -->
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th v-for="dt in headerRecords" class="text-left">{{ dt }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="groupRec in reportGroups">
              <tr v-if="groupRec.staffs.length > 0">
                <td>【{{ groupRec.group.name }}】</td>
                <td colspan="10"></td>
              </tr>

              <tr v-for="staffRec in groupRec.staffs" :key="staffRec.staff_cd">
                <td>
                  <span class="pl-5">{{ staffRec.name }}</span>
                </td>
                <td class="text-left" v-for="exists in staffRec.records">
                  <span v-if="exists"><v-icon>check</v-icon></span>
                  <span v-else></span>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>

    <v-container v-if="loading" fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-progress-circular
            v-if="loading"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import moment from "moment";
import Common from "@/components/Common";
import EntryCheckAPI from "@/apis/EntryCheckAPI";
import DateInputField from "../components/controls/DateInputField";
import Util from "@/Util";

export default {
  mixins: [Common],
  components: {
    DateInputField,
  },
  mounted: function () {
    let m = moment();
    let h = m.hour();

    if (h < 17) {
      m.subtract(1, "days");
    }

    this.selectedDate = m.format("YYYY-MM-DD");

    this.fetchData();

    // イベントハブのリッスンを開始
    this.$eventHub.$off("report_read", this.onReportRead);
    this.$eventHub.$on("report_read", this.onReportRead);

    this.$eventHub.$off("report_unread", this.onReportUnread);
    this.$eventHub.$on("report_unread", this.onReportUnread);

    this.checkHeight();

    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    this.$eventHub.$off("report_read", this.onReportRead);
    this.$eventHub.$off("report_unread", this.onReportUnread);

    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    //'month':function (to, from) {
    //    this.makeData()
    //},
  },
  data() {
    return {
      loading: false,
      downloading: false,

      selectedDate: "",

      headerRecords: [],

      reportGroups: [],

      records: [],

      containerHeight: 100,
    };
  },
  computed: {
    tableHeight() {
      return "" + this.containerHeight + "px";
    },
  },
  methods: {
    checkHeight() {
      let clientHeight = this.$refs.dailyviewcontainer.clientHeight;
      //
      //let tableTop = this.$refs.dailyviewtable.offsetTop;

      //console.log("clientHeight = " + clientHeight + ", tableTop = " + tableTop);

      this.containerHeight = clientHeight - 200;
    },

    handleResize() {
      this.checkHeight();
    },

    fetchData() {
      let self = this;

      if (!this.selectedDate) {
        return;
      }

      this.loading = true;

      this.reportGroups.splice(0);

      let dateStr = moment(this.selectedDate).format("YYYY-MM-DD");

      let requestData = {
        toDate: dateStr,
        days: 10,
      };

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return EntryCheckAPI.getData(requestData);
        })
        .then((a) => {
          this.makeData(a.data);

          this.loading = false;
        })
        .catch(function (err) {
          self.loading = false;
          console.log(err);
          self.showErrorToast("データの取得に失敗しました。");
        });
    },

    makeData(responseData) {
      console.log(responseData);

      this.reportGroups.splice(0);
      this.headerRecords.splice(0);

      let groupRecords = responseData.groups;
      let entryRecords = responseData.entries;

      entryRecords.forEach((dateRec) => {
        let dt = dateRec.date;
        this.headerRecords.push(this.formatDate(dt, "MM/DD"));
      });

      groupRecords.forEach((groupRecord) => {
        this.reportGroups.push(groupRecord);

        groupRecord.staffs.forEach((staff) => {
          let records = [];
          entryRecords.forEach((dateRec) => {
            let exists = !!dateRec.reports.find(function (element) {
              return element.staff_cd === staff.staff_cd;
            });

            records.push(exists);
          });

          staff.records = records;
        });
      });
    },

    onSelectReport(report) {
      //this.reportDialogRef.open(report.report_id,{});
    },

    onReportRead(data) {
      let reportId = data.reportId;

      //console.log("onReportRead " + reportId);

      this.records.forEach((groupRec) => {
        groupRec.staffs.forEach((staffRec) => {
          staffRec.reports.forEach((report) => {
            if (report.report_id == reportId) {
              report.isAlreadyRead = true;
              report.isUpdated = false;
            }
          });
        });
      });
    },

    onReportUnread(data) {
      let reportId = data.reportId;

      //console.log("onReportRead " + reportId);

      this.records.forEach((groupRec) => {
        groupRec.staffs.forEach((staffRec) => {
          staffRec.reports.forEach((report) => {
            if (report.report_id == reportId) {
              report.isAlreadyRead = false;
              report.isUpdated = false;
            }
          });
        });
      });
    },

    onDownloadClick() {
      let self = this;

      if (!this.selectedDate) {
        return;
      }

      this.downloading = true;

      let y = moment(this.selectedDate).year();
      let m = moment(this.selectedDate).month() + 1;

      console.log("onDownloadClick y:" + y + " m:" + m);

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return EntryCheckAPI.export(y, m);
        })
        .then((a) => {
          console.log(a);
          let downloadId = a.data.downloadId;
          let url = this.buildApiServerUrl(a.data.url);
          let filename = a.data.fileName;

          this._downloadFile(url, filename).then((a) => {
            self.downloading = false;
          });
        })
        .catch(function (err) {
          self.downloading = false;
          console.log(err);
          self.showErrorToast("データの取得に失敗しました。");
        });
    },

    _downloadFile(url, fileName) {
      let authToken = this.$store.state.account.idToken;
      return fetch(url, {
        method: "GET",
        headers: new Headers([["Authorization", authToken]]),
      })
        .then((response) => response.blob())
        .then((blob) => {
          let anchor = document.createElement("a");
          anchor.href = window.URL.createObjectURL(blob);
          anchor.download = fileName;
          anchor.click();
        });
    },

    buildApiServerUrl(path) {
      return Util.combineUrl(process.env.VUE_APP_API_HOST, path);
    },
  },
};
</script>
<style lang="scss" scoped></style>
