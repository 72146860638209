<script>
import ReportForwardAPI from "@/apis/ReportForwardAPI";
import Common from "./Common";
import RoleSupport from "@/components/RoleSupport";

export default {
  mixins: [Common, RoleSupport],
  mounted: function () {},

  data() {
    return {};
  },
  computed: {
    reportDialogRef: function () {
      return this.$store.state.commonRefs.reportDialogRef;
    },
    commentDialogRef: function () {
      return this.$store.state.commonRefs.commentDialog;
    },
    replyDialogRef: function () {
      return this.$store.state.commonRefs.replyDialog;
    },
    forwardDialogRef: function () {
      return this.$store.state.commonRefs.forwardDialog;
    },
    imageViewerDialogRef: function () {
      return this.$store.state.commonRefs.imageViewerDialog;
    },

    useAutoRead() {
      if (!this.$store.state.profile.settings) {
        return true;
      } else {
        return this.$store.state.profile.settings.use_auto_read == 1;
      }
    },

    reportMap() {
      return this.$store.state.reports;
    },
  },
  methods: {
    //--------------------------------------------
    // 表示用データ作成
    //--------------------------------------------

    makeReportDisplayData(entrySet) {
      let self = this;

      let report = entrySet.report;
      let user = entrySet.user;
      let tags = entrySet.tags;
      let emotes = entrySet.emotes;
      let comments = entrySet.comments;
      let todo = entrySet.todo;
      let files = entrySet.files;

      let canForward = this.hasRole("CAN_REPORT_FORWARD");
      let canEdit = report.staff_cd === this.account.staff_cd;

      let isSelfReport = report.staff_cd === this.account.staff_cd;

      let publicScopeType = report.scope_type; // 0:通常 1:グループ長のみ
      if (publicScopeType != 0) {
        // 特殊な表示権限の場合には転送不可
        canForward = false;
      }

      //console.log("    title: " + report.title);

      //console.log("makeReportDisplayData entrySet", entrySet);

      let commentRecords = comments.map((cmt) => {
        return self.makeCommentDisplayData(cmt);
      });

      let forwards = entrySet.forwards;

      if (!canForward && !isSelfReport) {
        // 転送権限を持っていない場合は自分向けの転送以外は表示させない
        // ただし、自分の日報の時は閲覧可能とする
        forwards = forwards.filter((f) => {
          return f.staff_cd == this.account.staff_cd;
        });
      }

      let readsStaffs = entrySet.reads.reads.map((r) => {
        return self.getUserName(r.staff_cd);
      });
      let totalReadCount = entrySet.reads.totalCount;

      let parsedContent = [];
      entrySet.extraContents.forEach((exc) => {
        parsedContent.push(
          this.makeContentStruct(exc.content_type, exc.content)
        );
      });

      if (report.content) {
        parsedContent.push(
          this.makeContentStruct("A0", report.content, "その他")
        );
      }

      let reportEntry = {
        id: report.id,
        entry_type: report.report_type,
        date: report.dt,
        all_day: report.all_day,
        start_time: report.start_time,
        end_time: report.end_time,
        title: report.title,
        content: report.content,
        parsedContentBlocks: parsedContent,
        user_name: user.name,
        goodOrBad: entrySet.goodOrBad,
        importance: entrySet.importance,
        tags: tags,
        emote: self.makeEmoteDisplayData(emotes),
        comments: commentRecords,
        files: files,
        forwards: forwards,
        readsStaffs: readsStaffs,
        totalReads: totalReadCount,
        is_self_report: isSelfReport,
        can_edit: canEdit,
        can_forward: canForward,
        new_arrivals: entrySet.isNew,
        already_read: entrySet.isAlreadyRead,
        updated: entrySet.isUpdated,
        read_event_fired: false,
        is_bookmarked: entrySet.isBookmarked,
        publicScopeType: publicScopeType,
      };

      //reportEntry.goodOrBad = 'G';
      //reportEntry.importance = '3';

      if (report.report_type === "B") {
        reportEntry.todoList = todo.map((a) => {
          return {
            title: a.todo.todo.name,
            state: a.status,
            note: a.comment,
          };
        });
      }

      return reportEntry;
    },

    makeContentStruct(contentType, content, title) {
      if (!title) {
        if (contentType == "A1") {
          title = "気付き/課題/提案/バッドニュース";
        } else if (contentType == "A2") {
          title = "ポジティブストーリー（グッドニュース/感謝など）";
        } else if (contentType == "A3") {
          title = "今日のトピックス（業務内容、出張報告、取引先情報など）";
        } else if (contentType == "A4") {
          title = "KPI進捗状況";
        }
      }

      return {
        title: title,
        contents: this.parseReportContent(content),
        contentType: contentType,
      };
    },

    makeCommentDisplayData(commentData) {
      let self = this;

      let commentRec = commentData.comment;
      let commentUser = commentData.user;
      let repliesRecs = commentData.replies;
      let emotes = commentData.emotes;

      let replies = repliesRecs.map((r) => this.makeReplyDisplayData(r));

      //console.log("makeCommentDisplayData commentData", commentData);
      //console.log("makeCommentDisplayData account", this.account);

      let canEdit = commentUser.staff_cd === this.account.staff_cd;

      return {
        comment_id: commentRec.id,
        report_id: commentRec.report_id,
        user_name: commentUser.name,
        staff_cd: commentRec.staff_cd,
        time: commentRec.comment_time,
        content: commentRec.content,
        parsedContent: this.parseReportContent(commentRec.content),
        emote: self.makeEmoteDisplayData(emotes),
        replies: replies,
        can_edit: canEdit,
      };
    },

    makeReplyDisplayData(replyData) {
      let self = this;

      //console.log("makeReplyDisplayData");
      //console.log(replyData);

      let replyRec = replyData.reply;
      let replyUser = replyData.user;
      let emotes = replyData.emotes;

      let canEdit = replyUser.staff_cd === this.account.staff_cd;

      return {
        reply_id: replyRec.id,
        comment_id: replyRec.comment_id,
        staff_cd: replyRec.staff_cd,
        user_name: replyUser.name,
        time: replyRec.reply_time,
        content: replyRec.content,
        parsedContent: this.parseReportContent(replyRec.content),
        emote: self.makeEmoteDisplayData(emotes),
        can_edit: canEdit,
      };
    },

    makeEmoteDisplayData(emoteData) {
      let self = this;

      //console.log(emoteData);

      if (!emoteData) {
        return {
          emotes: [],
          emote_count: 0,
          popup_data: {},
        };
      }

      let emoteList = emoteData.records;

      // サマリー情報を作成する
      // ・行われたEmote
      // ・件数

      let totalCount = emoteList.reduce((acc, value, index) => {
        return acc + value.records.length;
      }, 0);

      let emoteCodes = [];
      let b = emoteList.reduce((acc, value, index) => {
        acc.push(value.emote_cd);
        return acc;
      }, emoteCodes);

      //console.log(b);

      // ポップアップ用のデータを作成する
      // Emoteの種類
      //   ・・ 毎の登録者
      let popupList = emoteList.map(function (rec) {
        let emoteCd = rec.emote_cd;

        let staffs = rec.records.map(function (rec2) {
          return self.getUserName(rec2.staff_cd);
        });

        return {
          emote_cd: emoteCd,
          staff_names: staffs,
        };
      });

      let popupData = {};
      popupList.forEach(function (p) {
        popupData[p.emote_cd] = p;
      });

      return {
        emotes: b,
        emote_count: totalCount,
        popup_data: popupData,
      };
    },

    // 日報の内容をテキスト、URLで分割します。
    parseReportContent(text) {
      let analyzeResult = [];

      let regexp_url = /(https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+)/g; // ']))/;
      let matchResult = text.match(regexp_url);

      let wkText = text;
      if (matchResult) {
        matchResult.forEach((url) => {
          let urlLength = url.length;
          let startIndex = wkText.indexOf(url);
          if (startIndex > -1) {
            // startIndex の位置までを文字列として切り出す
            let t1 = "";
            if (startIndex > 0) {
              t1 = wkText.substr(0, startIndex);
            }

            // url以降を文字列として切り出す
            let afterIndex = startIndex + urlLength;
            let t2 = wkText.substr(afterIndex);

            analyzeResult.push({
              type: "text",
              value: t1,
            });
            analyzeResult.push({
              type: "url",
              value: url,
            });

            wkText = t2;
          }
        });
      }

      if (wkText) {
        analyzeResult.push({
          type: "text",
          value: wkText,
        });
      }

      return analyzeResult;
    },

    //--------------------------------------------
    // 日報編集
    //--------------------------------------------

    onEditReportClick(entry) {
      let reportDate = entry.date;
      let url = this.buildUrl("/entry/", { dt: reportDate });
      this.$router.push(url);
    },

    //--------------------------------------------
    // コメントダイアログの処理
    //--------------------------------------------

    async onCommentBtnClick(parentEntry, commentEntry) {
      let self = this;

      let report_id = parentEntry.id;
      let entry = parentEntry;
      let comment_id = null;
      let originalMessage = "";
      let staffCd = this.account.staff_cd;

      if (!this.commentDialogRef) {
        console.log("commentDialogがrefsに見つかりませんでした。");
        return;
      }

      let canDelete = false;

      if (commentEntry) {
        comment_id = commentEntry.comment_id;
        originalMessage = commentEntry.content;

        if (!!commentEntry.replies && commentEntry.replies.length > 0) {
          canDelete = false;
        } else {
          canDelete = true;
        }
      }

      let dialogResult = await this.commentDialogRef.open(
        staffCd,
        report_id,
        comment_id,
        originalMessage,
        canDelete,
        {}
      );
      let mode = dialogResult[0];
      let responseData = dialogResult[1];

      if (mode === 1) {
        let commentData = self.makeCommentDisplayData(responseData);

        self.$store.commit("setReportComment", {
          reportId: report_id,
          commentId: comment_id,
          data: commentData,
        });
      } else if (mode === 9) {
        if (comment_id) {
          let updateIdx = entry.comments.findIndex(
            (v) => v.comment_id == comment_id
          );
          if (updateIdx > -1) {
            entry.comments.splice(updateIdx, 1);
          }

          self.$store.commit("delReportComment", {
            reportId: report_id,
            commentId: comment_id,
          });
        }
      }
    },

    //--------------------------------------------
    // 返信ダイアログの処理
    //--------------------------------------------

    async onReplyBtnClick(parentEntry, replyEntry) {
      let self = this;

      let reportId = parentEntry.report_id;
      let commentId = parentEntry.comment_id;
      let staffCd = this.account.staff_cd;

      let originalMessage = "";
      let updateReplyId = null;
      if (replyEntry) {
        updateReplyId = replyEntry.reply_id;
        originalMessage = replyEntry.content;
      }

      if (!this.replyDialogRef) {
        console.log("replyDialogがrefsに見つかりませんでした。");
        return;
      }

      let dialogResult = await this.replyDialogRef.open(
        staffCd,
        reportId,
        commentId,
        updateReplyId,
        originalMessage,
        {}
      );
      let mode = dialogResult[0];
      let responseData = dialogResult[1];
      if (mode === 1) {
        let replyData = self.makeReplyDisplayData(responseData);

        self.$store.commit("setReportReply", {
          reportId: reportId,
          commentId: commentId,
          replyId: updateReplyId,
          data: replyData,
        });
      } else if (mode === 9) {
        self.$store.commit("delReportReply", {
          reportId: reportId,
          commentId: commentId,
          replyId: updateReplyId,
        });
      }
    },

    //--------------------------------------------
    // 転送ダイアログの処理
    //--------------------------------------------

    async onForwardBtnClick(entry) {
      let self = this;
      let reportId = entry.id;

      if (!this.forwardDialogRef) {
        console.log("forwardDialogがrefsに見つかりませんでした。");
        return;
      }

      let dialogResult = await this.forwardDialogRef.open({});

      if (dialogResult[0]) {
        let result = dialogResult[1];
        let message = result.message;
        let forwardStaffs = result.staff_cd_list;

        this.saveForward(reportId, forwardStaffs, message);
      }
    },

    saveForward(reportId, forwardStaffs, comment) {
      let self = this;
      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return ReportForwardAPI.putForward(reportId, forwardStaffs, comment);
        })
        .then((response) => {
          console.log(response);
          self.showMessageToast("日報を転送しました。");
        })
        .catch(function (err) {
          console.log(err);
          self.showErrorToast("データの登録に失敗しました。");
        });
    },
  },
};
</script>
