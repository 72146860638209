import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.forwards && _vm.forwards.length > 0),expression:"!!forwards && forwards.length > 0"}],staticClass:"report_forwards"},[_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.forwardsSummary),function(forward,index){return _c(VCol,{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c(VCard,{staticClass:"d-flex pl-2 report_forward_card",attrs:{"outlined":""}},[_c('div',{staticClass:"pt-1"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("share")])],1),_c('div',{staticClass:"report_forward_content pr-2"},[_c(VContainer,{staticClass:"ma-0 pa-0"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c('span',{staticClass:"ml-1 caption"},[_vm._v(_vm._s(_vm.getUserName(forward.from_staff_cd))+" さんから ")]),_vm._l((forward.staff_cds),function(staffCd,staffIdx){return _c('span',{key:staffCd,staticClass:"caption"},[(staffIdx>0)?_c('span',[_vm._v("、")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getUserName(staffCd))+" ")])}),_c('span',{staticClass:"caption"},[_vm._v("さんへ転送")])],2)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"caption"},[_vm._v("『"+_vm._s(forward.comment)+"』")])],1)],1)],1)])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }