import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{ref:"dailyviewcontainer",staticClass:"daily-view",attrs:{"fill-height":""}},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("入力チェック")])]),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('DateInputField',{attrs:{"label":"検索日"},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VBtn,{staticClass:"primary mr-3",on:{"click":_vm.fetchData}},[_vm._v("検索")]),_c(VBtn,{attrs:{"loading":_vm.downloading},on:{"click":_vm.onDownloadClick}},[_vm._v("ダウンロード")])],1),_c(VRow,{ref:"dailyviewtable",attrs:{"dense":""}},[(!_vm.loading)?_c(VSimpleTable,{attrs:{"fixed-header":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_vm._l((_vm.headerRecords),function(dt){return _c('th',{staticClass:"text-left"},[_vm._v(_vm._s(dt))])})],2)]),_c('tbody',[_vm._l((_vm.reportGroups),function(groupRec){return [(groupRec.staffs.length > 0)?_c('tr',[_c('td',[_vm._v("【"+_vm._s(groupRec.group.name)+"】")]),_c('td',{attrs:{"colspan":"10"}})]):_vm._e(),_vm._l((groupRec.staffs),function(staffRec){return _c('tr',{key:staffRec.staff_cd},[_c('td',[_c('span',{staticClass:"pl-5"},[_vm._v(_vm._s(staffRec.name))])]),_vm._l((staffRec.records),function(exists){return _c('td',{staticClass:"text-left"},[(exists)?_c('span',[_c(VIcon,[_vm._v("check")])],1):_c('span')])})],2)})]})],2)]},proxy:true}],null,false,2306906203)}):_vm._e()],1),(_vm.loading)?_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"2"}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e()],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }